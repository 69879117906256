import React from 'react';
import Countdown from '../components/Countdown';
import KillList from '../components/KillList';
import ProtectList from '../components/ProtectList';
import SeerView from '../components/SeerView';

class NightPhase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {players: [], killPlayer: '', active: 0};
  }
  
  shouldComponentUpdate(nextProps) {
    if (this.props !== nextProps) {
      console.log("MUDANÇA PROPS NIGHT PHASE");
      return true;
    }
    return false;
  }
  
  render() {
    let room = this.props.room;
    let players = Object.values(room.state.players);
    let user = this.props.user;
    let timeNightPhase = room.state.game.timeNightPhase;
    console.log("TIME NIGHT PHASE: ", timeNightPhase);

    var civilianList = players.filter((player) => {
      return player.sessionId !== user.sessionId && player.alive;
    } );

    return (
      <>
      <div id="NightPhase">
        <header>
          <div className="container">
            <div className="icon-phase">
              <i className="fi flaticon-cloudy-night"></i>
            </div>
            <Countdown minutes={0} seconds={timeNightPhase ? timeNightPhase : 20} />
          </div>
        </header>
        <main className="main-body content">
          <div className="container">
            {user.role === 'Lobisomem' && user.alive &&
            <>
            <div className="icon-role">
              <i className="fi flaticon-wolf"></i>
            </div>
            <h2 className="phase-message">Você é o Lobisomem!</h2>
            </>
            }
            {user.role === 'Anjo' && user.alive &&
            <>
            <div className="icon-role">
              <i className="fi flaticon-angel"></i>
            </div>
            <h2 className="phase-message">Você é um Anjo!</h2>
            </>
            }
            {(user.role === 'Vidente' || user.role === 'Trouxa') && user.alive &&
            <>
            <div className="icon-role">
              <i className="fi flaticon-seer"></i>
            </div>
            <h2 className="phase-message">Você é um Vidente!</h2>
            </>
            }
            {user.role === 'Aldeão' && user.alive &&
            <>
            <div className="icon-role">
              <i className="fi flaticon-farmer"></i>
            </div>
            <h2 className="phase-message">Você é um Aldeão!</h2>
            </>
            }
            {!user.alive &&
            <>
            <div className="icon-role">
              <i className="fi flaticon-skull"></i>
            </div>
            <h2 className="phase-message">Você está morto!</h2>
            </>
            }
            {(user.role === 'Vidente' || user.role === 'Trouxa') && user.alive &&
            <>
            <h2 className="role-message">Você teve um sonho, e viu essa pessoa se transformando em um lobisomem:</h2>
            <div className="seer-name">
              <SeerView user={user} room={room} />
            </div>
            </>
            }
            {user.role === 'Anjo' && user.alive &&
            <>
            <h2 className="kill-message">Escolha alguém para proteger:</h2>
            <ul className="killList">
              <ProtectList user={user} room={room} />
            </ul>
            </>
            }
            {user.role === 'Lobisomem' && user.alive &&
            <>
            <h2 className="kill-message">Escolha seu alvo:</h2>
            <ul className="killList">
              <KillList user={user} room={room} />
            </ul>
            </>
            }
          </div>
        </main>
      </div>
      </>
    );
  }
}

export default NightPhase;