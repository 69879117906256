import React from 'react';
import Countdown from '../components/Countdown';
import Blood from '../parts/Blood';

class DayPhaseResolution extends React.Component {

  shouldComponentUpdate(nextProps) {
    if (this.props !== nextProps) {
      console.log("MUDANÇA PROPS DAY PHASE RESOLUTION");
      return true;
    }
    return false;
  }
  
    render() {
      let room = this.props.room;
      let user = this.props.user;
      let players = Object.values(room.state.players);
      var freshPlayerDeath = [];
      freshPlayerDeath = players.filter((player) => {
        return player.fresh && !player.alive;
      } );
      var randNumber = room.state.game.randomNumber;
      console.log(randNumber);
      console.log(Math.floor(randNumber * players.length));
      console.log('PLAYERS LENGT: ', players.length);
      console.log(players);
      return (
        <>
        <div id="DayPhaseResolution">
          {freshPlayerDeath.length > 0 && user.fresh && !user.alive &&
            <Blood />
          }
          <header>
            <div className="container">
              <div className="icon-phase">
                <i className="fi flaticon-sunset"></i>
              </div>
              <Countdown minutes={0} seconds={15} />
            </div>
          </header>
          <main className="main-body content">
            <div className="container">
              {freshPlayerDeath.length > 0 &&
                <>
                <div className="icon-message"><i className="flaticon-gallows"></i></div>
                {
                  randNumber < 0.25 ? <h2 className="phase-text">A aldeia revoltada com a ideia de <strong>{freshPlayerDeath[0].name}</strong> ser um bolsominion, decidem enforca-lo(a).</h2>
                  : randNumber < 0.5 ? <h2 className="phase-text">Todos acharam suspeito o fato de <strong>{freshPlayerDeath[0].name}</strong> ter pelo demais no corpo. "Eu apenas estou com preguiça de aparar" disse. Sua justificativa não foi o suficiente, os aldeões queriam sua cabeça.</h2>
                  : randNumber < 0.75 ? <h2 className="phase-text">Todos na aldeia apontaram o dedo para <strong>{freshPlayerDeath[0].name}</strong> que acabou indo para a forca.</h2>
                  : <h2 className="phase-text">A aldeia, em meio a dúvidas e suspeitas, decidiu enforcar <strong>{freshPlayerDeath[0].name}</strong>.</h2>
                }
                <h2 className="phase-message">{freshPlayerDeath[0].name} era <strong>{freshPlayerDeath[0].role}</strong>.</h2>
                </>
              }
              {freshPlayerDeath.length <= 0 &&
                <>
                <div className="icon-message"><i className="flaticon-gallows"></i></div>
                {
                  randNumber < 0.25 ? <h2 className="phase-text">Depois de jogarem jokenpô, adedanha, tirar no palito e fazer uni-duni-tê a aldeia não decidiu quem deveria sair do empate.</h2>
                  : randNumber < 0.5 ? <h2 className="phase-text">Após um longo debate, os aldeões não chegam em um consenso. Uma nova reunião é marcada para o próximo dia.</h2>
                  : randNumber < 0.75 ? <h2 className="phase-text">Após uma longa discussão, descobrimos que <strong>{players[Math.floor(randNumber * players.length)].name}</strong> não gosta de tomar banho. Fora isso a aldeia não decidiu mais nada.</h2>
                  : <h2 className="phase-text">Como a aldeia é muito desorganizada, ninguém chegou a um consenso de quem deveria ir à forca. Todos vão para suas casas com raiva.</h2>
                }
                <h2 className="phase-message">Ninguém foi enforcado.</h2>
                </>
              }
            </div>
          </main>
        </div>
        </>
      );
    }
}

export default DayPhaseResolution;