import React from 'react';

class Countdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {minutes: this.props.minutes, seconds: this.props.seconds};
      }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            } 
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }
    render() {
        const { minutes, seconds } = this.state
        return (
            <div className="countdown">
            { minutes === 0 && seconds === 0
                ? <div className="timesup">Acabou o tempo!</div>
                : <div className="timer"><i className="fi flaticon-hourglass"></i><span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span></div>
            }
            </div>
        );
    }
}

export default Countdown;