import React from 'react';
import * as Colyseus from "colyseus.js";
import './App.css';
import './Custom.js';
import StartScreen from './StartScreen';

console.log(process.env.NODE_ENV.trim());

const protocol = window.location.protocol.replace("http", "ws");
const endpoint = (process.env.NODE_ENV === "production")
  ? `wss://lobisomem.gabrielthiago.com.br/server/`
  : `${protocol}//${ window.location.hostname }:2567`;

export const client = new Colyseus.Client(endpoint);

class App extends React.Component {
  render(){
    return (
      <>
      <StartScreen />
      </>
    );
    
  }

}

export default App;