import React from 'react';
import WaitingRoom from './phases/WaitingRoom';
import RoomContext from './context/RoomContext'
import NightPhase from './phases/NightPhase';
import NightPhaseResolution from './phases/NightPhaseResolution';
import DayPhase from './phases/DayPhase';
import DayPhaseResolution from './phases/DayPhaseResolution';
import EndGame from './phases/EndGame';
import Loading from './components/Loading';
import Alert from './components/Alert';

class Room extends React.Component {
    static contextType = RoomContext;
    constructor(props) {
      super(props);
      this.state = {value: '', room: [], user: [], players: [], phase: '', timeDayPhase: '', timeNightPhase: '', message: '' };

    }
  
    render() {
      let room = this.context;
      var phase = this.state.phase;
      room.state.game.onChange = (changes) => {
        changes.forEach(change => {
          if(change.field === 'phase'){
            this.setState({ phase: change.value });
            console.log("Phase Change: ", change.value);
          }
          if(change.field === 'timeDayPhase'){
            this.setState({ timeDayPhase : change.value });
            console.log("timeDayPhase Change: ", change.value, this.state.timeDayPhase);
          }
          if(change.field === 'timeNightPhase'){
            this.setState({ timeNightPhase: change.value });
            console.log("timeNightPhase Change: ", change.value, this.state.timeNightPhase);
          }
        });
      };
      room.state.players.onChange = (player, key) => {
        console.log(player, "has been changed at", key);
        console.log(Object.values(room.state.players));
        this.setState({ players: Object.values(room.state.players) });
        this.setState({ user: this.state.players.find( player => player.sessionId === room.sessionId ) });
      };
      room.state.players.onAdd = (player, key) => {
        console.log(player, "has been added at", key);
        this.setState({ players: [...this.state.players, player] })
      };
      room.state.players.onRemove = (playerToRemove, key) => {
        console.log(playerToRemove, "has been removed at", key);
        this.setState({message: playerToRemove.name + " saiu da sala."});
        this.setState({players: this.state.players.filter(function(player) { 
          return player !== playerToRemove; 
      })});
      };
      let phaseComponent;
      if(phase === "WaitingRoom"){
        phaseComponent = <WaitingRoom room={room} players={this.state.players} user={this.state.user} />;
      }
      else if(phase === "NightPhase"){
        phaseComponent = <NightPhase room={room} user={this.state.user} />;
      }
      else if(phase === "NightPhaseResolution"){
        phaseComponent = <NightPhaseResolution room={room} user={this.state.user} />;
      }
      else if(phase === "DayPhase"){
        phaseComponent = <DayPhase room={room} players={this.state.players} user={this.state.user} />;
      }
      else if(phase === "DayPhaseResolution"){
        phaseComponent = <DayPhaseResolution room={room} user={this.state.user} />;
      }
      else if(phase === "EndGame"){
        phaseComponent = <EndGame room={room} user={this.state.user} />;
      }
      else{
        phaseComponent = <Loading />;
      }
      return (
        <div id="Room">
          <Alert room={room} message={this.state.message} />
          {phaseComponent}
        </div>
      );
    }
}

export default Room;