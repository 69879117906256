import React from 'react';

class KillList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {killPlayer: '', active: ''};
  }
  
  killPlayer(id){
    this.setState({ killPlayer: id });
  }

  toggleClass(i) {
    this.setState({ active: i });
    console.log('Toggle CLASS', this.state.active);
  }

  componentDidMount() {
    let room = this.props.room;
    let user = this.props.user;
    let timeNightPhase = room.state.game.timeNightPhase;
    this.timeout = setTimeout(() => {
      if(user.role === 'Lobisomem' && user.alive){
        room.send("killPlayer", { sessionId: this.state.killPlayer });
      }
    }, timeNightPhase ? timeNightPhase*1000 : 20000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  
  render() {
    let room = this.props.room;
    let players = Object.values(room.state.players);
    let user = this.props.user;
    var civilianList = players.filter((player) => {
      return player.sessionId !== user.sessionId && player.alive;
    } );

    return (
      <>
        {civilianList.map((d, i) => (
            <button className={this.state.active === i ? 'active': null} key={d.sessionId} onClick={() => {this.killPlayer(d.sessionId); this.toggleClass(i);}}>
                <i className="fi flaticon-skull"></i><span>{d.name}</span>
            </button>
        ))}
      </>
    );
  }
}

export default KillList;