import React from 'react';
import Config from '../components/Config';

class WaitingRoom extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: '', room: [], players: []};
    }

    shouldComponentUpdate(nextProps) {
      if (this.props.room !== nextProps.room) {
        console.log("WAITING ROOM - ROOM");
        return true;
      }
      if (this.props.user !== nextProps.user) {
        console.log("WAITING ROOM - USER");
        return true;
      }
      if (this.props.players !== nextProps.players) {
        console.log("WAITING ROOM - PLAYERS");
        return true;
      }
      return false;
    }

    componentDidMount() {
    }

    startGame(room){
      room.send("startGame");
      console.log("STARTGAME: ", room);
    }
  
    render() {
        var listPlayers = [];
        var players = [];
        var room = this.props.room;
        let user = this.props.user;
        players = this.props.players;
        console.log("Game: ", this.props.room.state.game.timeNightPhase);
        console.log('Players Length:', players.length)
        if(players){
          listPlayers = players.map((d, i) => <li className={"player-"+i} key={d.sessionId}>{d.name}</li>);
        }
        return (
          <>
          <div id="WaitingRoom">
            <header>
              <div className="container">
                {players.length > 0 && user.sessionId === players[0].sessionId &&
                <Config room={room} />
                }
              </div>
            </header>
            <main className="main-body content">
              <div className="container">
                <h2>Jogadores na sala:</h2>
                <ul className="playersList">{listPlayers}</ul>
                {user.sessionId === players[0].sessionId && players.length > 0 && players.length <= 4 ? 
                <h3 className="waitMessage">A sala precisa de no mínimo 5 jogadores</h3>
                : user.sessionId === players[0].sessionId && players.length > 4 ? 
                <>
                <div className="button-container">
                  <span className="mas">Boa sorte</span>
                  <button id="entrar" type="submit" name="Hover" onClick={() => this.startGame(this.props.room)}>Iniciar</button>
                </div>
                </>
                : <h3 className="waitMessage">Aguarde o líder iniciar a partida</h3>
                }
              </div>
            </main>
          </div>
          </>
        );
    }
}

export default WaitingRoom;