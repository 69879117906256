import React from 'react';

class Loading extends React.Component {
    render() {
        return (
          <h1>Carregando...</h1>
        );
    }
}

export default Loading;