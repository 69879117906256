import React from 'react';
import ReactDOM from 'react-dom';
import { client } from './App';
import Room from './room/Room';
import { RoomProvider } from './room/context/RoomContext'

class StartScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: '', room: [], user: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit(event) {
      if(this.state.value){
        client.joinOrCreate("my_room", {name: this.state.value}).then(room => {
          this.setState({ room: room });
          ReactDOM.render(
            <RoomProvider value={room}>
                <Room />
            </RoomProvider>,
            document.getElementById('root')
          );
        }).catch(e => {
          console.log("JOIN ERROR", e);
        });
      }
  
      event.preventDefault();
    }
  
    render() {
      return (
        <>
        <div id="StartScreen">
          <main className="main-body no-header content"> 
            <div className="container">
              <div className="game-logo">
                <h1 className="game-name">Lobisomem</h1>
                <h2 className="game-sub">- O Jogo -</h2>
              </div>
              <form className="form-section" onSubmit={this.handleSubmit}>
                <label>Digite seu nome: </label>
                <div className="input-name">
                  <input type="text" maxLength="12" value={this.state.value} onChange={this.handleChange} />
                </div>
                <div className="button-container">
                  <span className="mas">Entrar</span>
                  <button id="entrar" type="submit" name="Hover">Entrar</button>
                </div>
              </form>
            </div>
          </main>
        </div>
        </>
      );
    }
}

export default StartScreen;