import React from 'react';
class Blood extends React.Component {
    render(){
        return(
            <>
            <div className="blobs">
                <div className="liquid"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
                <div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div><div className="blob"></div>
            </div>  
            </>
        );
    };
}
  
export default Blood;