import React from 'react';

class SeerView extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    let room = this.props.room;
    let user = this.props.user;
    let players = Object.values(room.state.players);
    var lobisomem = players.find((player) => {
      return player.role === 'Lobisomem';
    } );
    var fooled = players.find((player) => {
        return player.fooled;
    });

    return (
      <>
        {user.role === 'Vidente' && lobisomem.name}
        {user.role === 'Trouxa' && fooled.name}
      </>
    );
  }
}

export default SeerView;