import React from 'react';

class EndGame extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: '', room: [], players: []};
    }

    shouldComponentUpdate(nextProps) {
      if (this.props.room !== nextProps.room) {
        console.log("ENDGAME - ROOM");
        return true;
      }
      if (this.props.user !== nextProps.user) {
        console.log("ENDGAME - USER");
        return true;
      }
      return false;
    }

    restartGame(room){
        room.send("restartGame");
    }
  
    render() {
        let room = this.props.room;
        let players = Object.values(room.state.players);
        const isWerewolfAlive = players.find(player => player.role === 'Lobisomem' && player.alive);
        return (
          <>
            <div id="EndGame">
              <main className="main-body content">
                <div className="container">
                  <h1 className="phase-message">O Jogo acabou!</h1>
                  {isWerewolfAlive &&
                  <h2 className="phase-message">Os lobisomens ganharam!</h2>
                  }
                  {!isWerewolfAlive &&
                  <h2 className="phase-message">Os Aldeões ganharam!</h2>
                  }
                  {players.map((player, i) => (
                      <h2>{player.name} era <strong>{player.role}</strong></h2>
                  ))}
                  {players.length > 0 && this.props.room.sessionId === players[0].sessionId && //Mostrar start se o player for o primeiro
                  <>
                  <div className="button-container">
                    <span className="mas">Reiniciar</span>
                    <button id="entrar" type="submit" name="Hover" onClick={() => this.restartGame(this.props.room)}>Reiniciar</button>
                  </div>
                  </>
                  }
                </div>
              </main>
            </div>
          </>
        );
    }
}

export default EndGame;