import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'


class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: false, message: ''};
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleClose() {
        this.setState({show: false});
        this.setState({message: ''});
    }
    handleShow(){
      this.setState({show: true});
    }
    handleChange(event) {
      const target = event.target;
      const value = parseInt(target.value);
      const name = target.name;
      this.setState({
        [name]: value
      });
    }
    componentDidUpdate(prevProps) {
      if (prevProps.message !== this.props.message) {
        var message = this.props.message;
        if(message){
          this.handleShow();
          this.setState({message: message});
        }
      }
    }
    render(){
        return(
            <>
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Body>
                <p>{this.state.message}</p>
              </Modal.Body>
              <Modal.Footer>
                <button className="close" onClick={this.handleClose}>OK</button>
              </Modal.Footer>
            </Modal>
            </>
        );
    };
}

  
export default Alert;