import React from 'react';

class HangList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hangPlayer: '', active: '', voted: false};
  }

  hangPlayer(){
    let room = this.props.room;
    if(this.state.hangPlayer){
      room.send("hangPlayer", { sessionId: this.state.hangPlayer });
      console.log("HANG PLAYER", this.state.hangPlayer);
      this.setState({ voted: true });
    }
  }

  togglePlayer(id){
    let user = this.props.user;
    if(!this.state.voted && user.alive){
      this.setState({ hangPlayer: id });
    }
  }

  toggleClass(i) {
    let user = this.props.user;
    if(!this.state.voted && user.alive){
      this.setState({ active: i });
      console.log('Toggle CLASS', this.state.active);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidMount() {
    let room = this.props.room;
    let timeDayPhase = this.props.timeDayPhase;
    this.timeout = setTimeout(() => {
      if(!this.state.voted){
        room.send("hangPlayer", { sessionId: '' });
        console.log("ACABOU TEMPO - HANG PLAYER", this.state.hangPlayer);
      }
    }, timeDayPhase ? timeDayPhase*1000 : 20000);
  }
  
  render() {
    let room = this.props.room;
    let players = this.props.players;
    let user = this.props.user;

    var playersList = players.filter((player) => {
      return player.alive;
    } );

    return (
      <>
        {playersList.map((d, i) => (
            <button className={this.state.active === i ? 'active hangButton': 'hangButton'} key={d.sessionId} onClick={() => {this.toggleClass(i); this.togglePlayer(d.sessionId)}}>
                <i className="fi flaticon-check check"></i><span>{d.name}</span>
                <div className="vote">{[...Array(d.votes)].map((_, i) => <i className="fi flaticon-close"></i>)}</div>
            </button>
        ))}
        {!this.state.voted && user.alive &&
        <div className="button-container">
          <span className="mas">Votar</span>
          <button onClick={() => {this.hangPlayer()}}>Votar</button>
        </div>
        }
      </>
    );
  }
}

export default HangList;