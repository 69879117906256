import React from 'react';
import Countdown from '../components/Countdown';
import HangList from '../components/HangList';

class DayPhase extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: '', room: [], players: [], hangPlayer: ''};
    }

    shouldComponentUpdate(nextProps) {
      if (this.props.room !== nextProps.room) {
        console.log("DAYPHASE - ROOM");
        return true;
      }
      if (this.props.user !== nextProps.user) {
        console.log("DAYPHASE - USER");
        return true;
      }
      if (this.props.players !== nextProps.players) {
        console.log("DAYPHASE - PLAYERS");
        return true;
      }
      return false;
    }
  
    render() {
      let room = this.props.room;
      let user = this.props.user;
      let players = this.props.players;
      let timeDayPhase = room.state.game.timeDayPhase;
      return (
        <>
          <div id="DayPhase">
            <header>
              <div className="container">
                <div className="icon-phase">
                  <i className="fi flaticon-sun"></i>
                </div>
                <Countdown minutes={0} seconds={timeDayPhase ? timeDayPhase : 20} />
              </div>
            </header>
            <main className="main-body content">
              <div className="container">
                <h2 className="phase-text">Após os acontecimentos da noite anterior, os aldeões se reunem para discutir quem deve ir para a forca.</h2>
                {!user.alive &&
                <>
                <ul className="hangList">
                  <HangList players={players} user={user} room={room} timeDayPhase={timeDayPhase} />
                </ul>
                <h2 className="phase-message">Você está morto, não pode votar.</h2>
                </>
                }
                {user.alive &&
                <>
                <h2 className="phase-message">Quem devemos enforcar?</h2>
                <ul className="hangList">
                  <HangList players={players} user={user} room={room} timeDayPhase={timeDayPhase} />
                </ul>
                </>
                }
              </div>
            </main>
          </div>
        </>
      );
    }
}

export default DayPhase;