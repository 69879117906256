import React from 'react';
import Countdown from '../components/Countdown';
import Blood from '../parts/Blood';

class NightPhaseResolution extends React.Component {

  shouldComponentUpdate(nextProps) {
    if (this.props !== nextProps) {
      console.log("MUDANÇA PROPS NIGHT PHASE RESOLUTION");
      return true;
    }
    return false;
  }

    render() {
      let room = this.props.room;
      let user = this.props.user;
      let players = Object.values(room.state.players);
      var freshPlayerDeath = [];
      var freshPlayerProtected = [];
      var randNumber = room.state.game.randomNumber;
      freshPlayerDeath = players.filter((player) => {
        return player.fresh && !player.alive;
      } );
      freshPlayerProtected = players.filter((player) => {
        return player.fresh && player.protected;
      } );
      return (
        <>
        <div id="NightPhaseResolution"> 
          {freshPlayerDeath.length > 0 && user.fresh && !user.alive &&
            <Blood />
          }
          <header>
            <div className="container">
              <div className="icon-phase">
                <i className="fi flaticon-sunset"></i>
              </div>
              <Countdown minutes={0} seconds={15} />
            </div>
          </header>
          <main className="main-body content">
            <div className="container">
              {freshPlayerDeath.length > 0 &&
                <>
                <div className="icon-message"><i className="flaticon-dead-body"></i></div>
                {
                  randNumber < 0.25 ? <h2 className="phase-text">O sol nasce, os aldeões caminham para suas tarefas rotineiras e encontram <strong>{freshPlayerDeath[0].name}</strong>, sem vida, dentro da fonte na praça central.</h2>
                  : randNumber < 0.5 ? <h2 className="phase-text">O corpo de <strong>{freshPlayerDeath[0].name}</strong> é encontrado esquartejado no bosque atrás da aldeia. Todos se questionam sobre o acontecido.</h2>
                  : randNumber < 0.75 ? <h2 className="phase-text">Após uma boa noite de sono, os aldeões acordam e se deparam com o corpo de <strong>{freshPlayerDeath[0].name}</strong> na porta de sua casa.</h2>
                  : <h2 className="phase-text">O dia amanhece, os aldeões se levantam e saem de suas casas. Um forte cheiro os guiam para o corpo de <strong>{freshPlayerDeath[0].name}</strong> já sem vida.</h2>
                }
                <h2 className="phase-message">{freshPlayerDeath[0].name} era <strong>{freshPlayerDeath[0].role}</strong>!</h2>
                </>
              }
              {freshPlayerProtected.length > 0 &&
                <>
                <div className="icon-message"><i className="flaticon-angel"></i></div>
                <h2 className="phase-text">Um aldeão corre em meio a floresta, perseguido por um imenso vulto negro coberto de pelos e dentes afiados. A bizarra figura estende suas garras para atingir o aldeão. Um feixe de luz desce do céu como um raio, bloqueando a investida da besta que, desnorteada, corre de volta em direção ao arvoredo.</h2>
                <h2 className="phase-message"><strong>{freshPlayerProtected[0].name}</strong> foi protegido(a) da morte pelo anjo.</h2>
                </>
              }
              {freshPlayerDeath.length <= 0 && freshPlayerProtected.length <=0 &&
                <>
                <h2 className="phase-text">O dia amanhece, os passáros entonam notas de uma canção, as árvores dançam ao suave sopro dos ventos vindos do sul.</h2>
                <h2 className="phase-message">Ninguém morreu esta noite.</h2>
                </>
              }
            </div>
          </main>
        </div>
        </>
      );
    }

}

export default NightPhaseResolution;